import { FC, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import IconUser from '@svg-icons/IconUser';
import styles from './css';

type AvatarSize = 'sm' | 'md' | 'lg' | 'xl';

type AvatarProps = {
  nickname?: string;
  size: AvatarSize;
  src?: string | null;
};

const IMAGE_SIZE: Record<AvatarSize, number> = {
  lg: 64,
  md: 40,
  sm: 32,
  xl: 96,
};

const Avatar: FC<AvatarProps> = ({ nickname, size, src }) => {
  const [isImageMissing, setIsImageMissing] = useState(false);

  const showImage = src && !isImageMissing;

  return (
    <div
      className={classNames('avatar', `avatar-${size}`, {
        'no-image': !showImage,
      })}
    >
      {showImage ? (
        <Image
          alt={nickname || ''}
          height={IMAGE_SIZE[size]}
          src={src}
          width={IMAGE_SIZE[size]}
          onError={() => setIsImageMissing(true)}
        />
      ) : (
        <IconUser color="white" size={size} withFill={false} />
      )}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default Avatar;
