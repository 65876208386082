import { FC, useEffect } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import IconAddCoin from '@assets/icon-add-coin.svg';
import IconBookOpen from '@assets/icon-book-open.svg';
import Button from '@atoms/Button';
import { TypoXXXSBold } from '@atoms/Typos';
import { Device } from '@components/Layout';
import Link from '@components/Link';
import IconWithText from '@components/molecules/IconWithText';
import { useActiveCoinPack } from '@hooks/useActiveCoinPack';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  useCampaignOnClickPushDataLayer,
  useShowFlightTicketPrizeCampaign,
} from '@hooks/useCampaign';
import { useNeighbor } from '@hooks/useNeighbor';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import NavIconList from '@molecules/NavIconList';
import ProfileIcon from '@molecules/ProfileIcon';
import * as pendingBonusCoinsActions from '@redux/modules/pendingBonusCoins';
import IconCoin from '@svg-icons/IconCoin';
import { useActions } from '@utils/hooks';
import { getNeighborSubscribed } from '@utils/neighbor';
import routes from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import { isEmpty } from '@utils/utils';
import HeaderLink from './HeaderLink';
import HeaderLogo from './HeaderLogo';
import styles from './css';

type HeaderProps = {
  isLogoClickDisabled?: boolean;
  isLogoH1?: boolean;
  isOnlyLogo?: boolean;
};

const Header: FC<HeaderProps> = ({
  isLogoClickDisabled,
  isLogoH1,
  isOnlyLogo = false,
}) => {
  const { authUser, loadingAuthUser, pendingBonusCoins } = useAppSelector(
    (state) => ({
      authUser: state.authUser,
      loadingAuthUser: state.loadingAuthUser,
      pendingBonusCoins: state.pendingBonusCoins,
    })
  );
  const { getPendingBonusCoins } = useActions({
    ...pendingBonusCoinsActions,
  });

  const { isLoggedIn, loadingNeighbor, neighbor, vipRank } = useNeighbor();
  const { isRegistrationCountryJapan } = useRegistrationCountry();
  const { t } = useTranslation('header');
  const { t: navT } = useTranslation('nav-icons');
  const router = useRouter();
  const { locale, pathname } = router;

  const isSignedIn = !isEmpty(neighbor);
  const isSubscribed = getNeighborSubscribed(neighbor);

  const {
    activeCoinPackCode,
    isCoinPackCampaignActive,
    isSignUpCoinPackageActive,
  } = useActiveCoinPack();

  // FIXME - purchase_coin_packages does not exist on generated Neighbor type
  // @ts-ignore
  const purchaseCoinPackages = neighbor?.purchase_coin_packages;

  const isCoinPurchaseBonusQualified =
    vipRank === 'gold' || vipRank === 'platinum';

  const campaignOnClickPushDataLayer = useCampaignOnClickPushDataLayer();
  const showFlightTicketPrizeCampaign = useShowFlightTicketPrizeCampaign();

  useEffect(() => {
    if (isLoggedIn) {
      getPendingBonusCoins(authUser, locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, isLoggedIn, locale]);

  return (
    <header className="header">
      <div
        className={classNames(
          'header__wrapper',
          isOnlyLogo ? 'header__wrapper--only-logo' : ''
        )}
      >
        <div className="header__logo">
          <HeaderLogo
            isLogoClickDisabled={isLogoClickDisabled}
            isLogoH1={isLogoH1}
            isSignedIn={isSignedIn}
          />
        </div>
        {!isOnlyLogo && (
          <div className="header__menu menu">
            {loadingAuthUser || loadingNeighbor ? (
              <Image alt="loading" height={8} src="/loading.gif" width={30} />
            ) : isSignedIn ? (
              <div className="menu__list list">
                <HeaderLink t={t} />
                <Device desktop={true} tablet={true}>
                  <div className="header__icons">
                    <NavIconList />
                  </div>
                </Device>
                {isSubscribed ? (
                  <div className="header-coin-cont">
                    <div className="coin-nav-promotion-label-cont">
                      {isSignUpCoinPackageActive ? (
                        <div className="coin-on-sale">
                          <TypoXXXSBold color="purple600">
                            <>
                              <span>{t('signUpCoinPack')}</span>
                              &nbsp;
                            </>
                          </TypoXXXSBold>
                          <TypoXXXSBold color="purple600">
                            <>
                              <span>{t('signUpCoinPack')}</span>
                              &nbsp;
                            </>
                          </TypoXXXSBold>
                        </div>
                      ) : showFlightTicketPrizeCampaign ||
                        isCoinPackCampaignActive ? (
                        <div className="flight-prize-campaign">
                          <TypoXXXSBold text="キャンペーン開催中!" />
                        </div>
                      ) : (
                        isCoinPurchaseBonusQualified && (
                          <div className="vip-coin-bonus">
                            <TypoXXXSBold
                              color="purple600"
                              text={t('vipCoinBonus')}
                            />
                          </div>
                        )
                      )}
                    </div>
                    <Link
                      className="item__link link test-header-coin"
                      href={routes.coinHistory}
                      onClick={() => {
                        campaignOnClickPushDataLayer({
                          event_name: 'header_coin-icon',
                          type: 'coinpack',
                        });
                      }}
                    >
                      <>
                        <span className="link__icon">
                          {isRegistrationCountryJapan ? (
                            <IconAddCoin />
                          ) : (
                            <IconCoin />
                          )}
                          {pendingBonusCoins?.pending_bonus_coins &&
                            pathname !== routes.coinHistory && (
                              <div className="claim-coins">
                                <TypoXXXSBold
                                  color="white"
                                  text={t('claimCoins')}
                                />
                              </div>
                            )}
                        </span>
                        <p className="link__label">{neighbor.coin}</p>
                      </>
                    </Link>
                  </div>
                ) : (
                  <div className="menu__not-login not-login">
                    <ul className="not-login__buttons buttons">
                      <li className="buttons__button subscribe-button">
                        <Button
                          btnStyle="signup"
                          label={t('subscribe')}
                          testId="header-register"
                          onClick={() => {
                            pushDataLayer({
                              campaign_code: '',
                              event: 'gtm-click',
                              event_name: 'header_subscribe',
                              neighbor_id: neighbor.id,
                            });
                            router.push(routes.subscribe);
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                )}
                <Link href={routes.profile}>
                  <ProfileIcon neighbor={neighbor} size="md" />
                </Link>
              </div>
            ) : (
              <div className="menu__not-login not-login">
                <ul className="not-login__buttons buttons">
                  <Device desktop={true} tablet={true}>
                    <li className="buttons__button">
                      <Link href={routes.guide}>
                        <IconWithText
                          Icon={IconBookOpen}
                          isActive={pathname === routes.guide}
                          text={navT('guide')}
                        />
                      </Link>
                    </li>
                  </Device>
                  {!isSignedIn && (
                    <li className="buttons__button buttons__button--login">
                      <Button
                        isSecondary={true}
                        label={t('login')}
                        link={routes.signin}
                        testId="header-login"
                      />
                    </li>
                  )}
                  <li className="buttons__button">
                    <Button
                      btnStyle="signup"
                      label={t(isSignedIn ? 'subscribe' : 'register')}
                      testId="header-register"
                      onClick={() => {
                        if (isSignedIn) {
                          pushDataLayer({
                            campaign_code: '',
                            event: 'gtm-click',
                            event_name: 'header_subscribe',
                            neighbor_id: neighbor.id,
                          });
                          router.push(routes.subscribe);
                        } else {
                          router.push(routes.signup);
                        }
                      }}
                    />
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </header>
  );
};

export default Header;
